const localUrl = "http://localhost:3000/";
const remoteUrl = "https://us-central1-athena-app-99a72.cloudfunctions.net/api/";
const baseDevUrl = "http://localhost:3001/#/";
const baseProdUrl = "https://athena-app-99a72.web.app";
export const config: any = {
  base: {
    development: baseDevUrl,
    production: baseProdUrl,
  },
  api: {
    url: {
      development: localUrl,
      production: remoteUrl,
    },
  },
  firebase: {
    apiKey: "AIzaSyDQs7ZhhWHAK0TdTCIVsAmULoCPEhK3euM",
    authDomain: "athena-app-99a72.firebaseapp.com",
    databaseURL: "https://athena-app-99a72.firebaseio.com",
    projectId: "athena-app-99a72",
    storageBucket: "athena-app-99a72.appspot.com",
    messagingSenderId: "588030538494",
    appId: "1:588030538494:web:a0bee8254c186280ebabaf",
    measurementId: "G-CMVX8XNMWE",
  },
};

export default config;
