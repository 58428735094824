import * as React from "react";
import { Component } from "react";
import Loadable from "react-loadable";
import { HashRouter, Route, Switch  } from "react-router-dom";
import "./App.scss";
import { FirebaseHelper } from "./lib/firebase/FirebaseHelper";
import Loading from "./components/Loading/Loading";

const loading = () => (
    <Loading/>
  );

// Containers
const DefaultLayout = Loadable({
  loader: () => import ("./containers/DefaultLayout/DefaultLayout"),
  loading,
});

// Pages
const Login = Loadable({
  loader: () => import(`./views/Pages/Login/Login`),
  loading,
});

const GrowerSignUp = Loadable({
  loader: () => import(`./views/Pages/GrowerSignUp/GrowerSignUp`),
  loading,
});

const EditProfile = Loadable({
  loader: () => import(`./views/EditProfile/EditProfile`),
  loading,
});

const Page404 = Loadable({
  loader: () => import(`./views/Pages/Page404/Page404`),
  loading,
});

const Page500 = Loadable({
  loader: () => import(`./views/Pages/Page500/Page500`),
  loading,
});

class App extends Component {
  public state = {
    userData: null,
    firebaseHelper: null,
    isLoading: true,
    isNewUser: true,
    isGrowerSignUp: false,
  };

  public async componentDidMount() {
    const firebaseHelper = new FirebaseHelper(async (user: any) => {

      const isGrowerSignUp = window.location.href.includes("growers/register");

      this.setState({
        isGrowerSignUp,
      });

      if (user) {
        const { displayName, uid, email  } = user;
        const userData: any = await firebaseHelper.fetchUserInfo(uid);
        const {isNewUser} = userData;

        this.setState({
          isLoading: false,
          userData: {
            ...userData,
            displayName,
            uid,
            email,
          },
          isNewUser,
        });
      } else {
        this.setState({
          isLoading: false,
          userData: null,
        });
      }
    });

    this.setState({
      firebaseHelper,
    });
  }

  public render() {
    const { firebaseHelper, userData, isLoading, isNewUser, isGrowerSignUp }: any = this.state;

    if (isLoading) {
      return (<div className="animated fadeIn pt-1 text-center"><div className="sk-spinner sk-spinner-pulse" /> Loading</div>);
    }

    if (isGrowerSignUp) {
      return (
        <HashRouter>
          <Switch>
            <Route
                render={(props) => (
                  <GrowerSignUp {...props} firebaseHelper={firebaseHelper} userData={userData} />
                )}
            />
          </Switch>
        </HashRouter>
      );
    }

    if (userData) {
      if (isNewUser) {
        return (
        <HashRouter>
          <Switch>
            <Route
                path="/"
                render={(props) => (<EditProfile {...props} firebaseHelper={firebaseHelper} isNewUser={isNewUser} userData={userData}/>)}
            />
          </Switch>
        </HashRouter>

        );
      }
      return (
        <HashRouter>
          <Switch>
            <Route
                path="/"
                render={(props) => (<DefaultLayout {...props} firebaseHelper={firebaseHelper} userData={userData}/>)}
            />
          </Switch>
        </HashRouter>
      );
    }

    return (
      <HashRouter>
        <Switch>
          <Route path="/404" name="Page 404" component={Page404} />
          <Route path="/500" name="Page 500" component={Page500} />
          <Route
              path="/"
              render={(props) => (<Login {...props} firebaseHelper={firebaseHelper} userData={userData}/>)}
          />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
