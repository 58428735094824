/* eslint-disable class-methods-use-this */
import * as firebase from "firebase";
import config from "../../config/config";
import { logger } from "../../lib/util/utl";
import { unsetCurrentDbIdx } from "../../lib/permission/permission";

const mode: string = process.env.NODE_ENV || "production";
const base: string = config.base[mode];

export let auth: firebase.auth.Auth;

export class FirebaseHelper {
  private app: any;
  private secondaryApp: any;
  constructor(onStateChange: any) {
    const {firebase: firebaseConfig} = config;
    this.app = firebase.initializeApp(firebaseConfig);
    this.secondaryApp = firebase.initializeApp(firebaseConfig, "secondary");
    if (onStateChange) {
      auth = firebase.auth();
      auth.onAuthStateChanged(onStateChange);
    }
  }

  public async currentUser() {
    return firebase.auth().currentUser;
  }

  public async sendPasswordResetEmail(email: string) {
    await firebase.auth(this.app).sendPasswordResetEmail(email);
  }

  public async signUp(email: string, password: string) {
    await firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  public async signIn(email: string, password: string) {
    unsetCurrentDbIdx();
    const confirmationResult = await firebase.auth(this.app).signInWithEmailAndPassword(email, password);
    const result = confirmationResult.additionalUserInfo?.isNewUser;
    const {uid}: any = confirmationResult.user;
    if (result === true) {
      const userRef = await firebase.firestore().collection(`Users`).doc(uid);
      userRef.set({
        ...userRef,
        isNewUser: result,
      });
    }
    const returningUser = await firebase.firestore().collection(`Users`).doc(uid);
    returningUser.set({
      ...returningUser,
      isNewUser: false,
    });
  }

  public async signOut() {
    return firebase.auth().signOut();
  }

  public async updatePassword(password: string) {
    try {
      const user = await this.currentUser();
      if (user) {
        return user.updatePassword(password);
      }
    } catch (error) {
      logger(error);
      throw new Error(error);
    }

    return false;
  }

  public async signInWithFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider();
    await firebase.auth().signInWithPopup(provider);
  }

  public async signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithPopup(provider);
  }

  public isSignedIn() {
    return firebase.auth().currentUser != null;
  }

  public async fetchUserInfo(userId: string) {
    try {
      const userRef = firebase.firestore().collection(`Users`).doc(userId);
      const userDoc = await userRef.get();
      const { exists } = userDoc;
      if (exists) {
        return userDoc.data();
      }
      return {};
    } catch (error) {
      logger(error);
      throw new Error(error);
    }
  }

  public async updateUserInfo(userId: string , userData: any) {
    try {
      const userRef = firebase.firestore().collection(`Users`).doc(userId);
      const userDoc = await userRef.get();
      const { exists } = userDoc;
      if (exists) {
        return userRef.update({
          ...userData,
          isNewUser: false,
        });
      }
      return userRef.set({
          ...userData,
          isNewUser: false,
      });
    } catch (error) {
      logger(error);
      throw new Error(error);
    }
  }

  public async signUpUsers(userInfo: any) {
    const actionCodeSettings = {
      url: base,
      handleCodeInApp: true,
    };
    const {
      userName,
      password,
      firstName,
      lastName,
      userType,
    } = userInfo;
    try {
      const userData = await firebase.auth(this.secondaryApp).createUserWithEmailAndPassword(userName, password);
      const { uid }: any = userData.user;
      const userRef =  await firebase.firestore().collection(`Users`).doc(uid);
      userRef.set({
        email: userName,
        firstName,
        lastName,
        userType,
        isNewUser: true,
      });
      const user: any = firebase.auth(this.secondaryApp).currentUser;
      const sendEmail = await user.sendEmailVerification(actionCodeSettings);
      return sendEmail;
    } catch (error) {
      logger(error);
      throw new Error(error);
    }
  }

  public async fetchAllUsers(dbIdx: string | number) {
    try {
      const userRef = await firebase.firestore().collection("Users");
      const query = userRef.where("dbIdx", "==", dbIdx.toString()).get()
        .then(((snapshot) => {
          const usersData: any = [];
          if (snapshot.empty) {
            return [];
          }
          snapshot.forEach((doc) => {
            const userInfo = doc.data();
            const userId = doc.id;
            usersData.push({ userId, ...userInfo });
          });
          return usersData;
        }));
      return query;
    } catch (error) {
      return error;
    }
  }

}

export async function currentUser() {
  return firebase.auth().currentUser;
}

export async function fetchUserInfo(userId?: string) {
  let id = userId;

  if (!userId) {
    const user: any = await currentUser();
    const { uid } = user;
    id = uid;
  }

  try {
    const userRef = firebase.firestore().collection(`Users`).doc(id);
    const userDoc = await userRef.get();
    const { exists } = userDoc;
    if (exists) {
      return userDoc.data();
    }
    return {};
  } catch (error) {
    logger(error);
    throw new Error(error);
  }
}

export default FirebaseHelper;
