import { fetchUserSources } from "../../api/UserApi";
import { currentUser, fetchUserInfo } from "../../lib/firebase/FirebaseHelper";

export async function getCurrentDbIdx(): Promise<number> {
  const storedDbIdx = localStorage.getItem("dbIdx");

  if (storedDbIdx) {
    return Number(storedDbIdx);
  }

  const { uid }: any = await currentUser();
  const { dbIdx }: any = await fetchUserInfo(uid);

  if (!dbIdx) {
    const sources = await fetchUserSources();

    if (!sources || sources.length < 1) {
      throw new Error("no sources available for user");
    }

    localStorage.setItem("dbIdx", sources[0].dbIdx.toString());
    return sources[0].dbIdx;
  }

  localStorage.setItem("dbIdx", dbIdx);
  return dbIdx;
}

export function changeLocalCurrentDbIdx(n: number) {
  localStorage.setItem("dbIdx", n.toString());
}

export function unsetCurrentDbIdx() {
  localStorage.setItem("dbIdx", "");
}
