import { ApiFetch } from "./Api";

interface ISource { name: string; dbIdx: number; }

interface IUserSources {
  rows: ISource[];
}

export const fetchUserSources = async () => {
  const data = await ApiFetch<IUserSources>("user/sources");
  const { rows } = data;

  return rows || [];
};
