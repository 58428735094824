import config from "../config/config";
import { auth } from "../lib/firebase/FirebaseHelper";
import queryString from "query-string";
import { getCurrentDbIdx } from "../lib/permission/permission";

const mode: string = process.env.NODE_ENV || "production";
const url: string = config.api.url[mode];

interface IFetchOptions {
  sample?: boolean;
  filter?: object;
  limit?: number;
  id?: any;
  query?: string;
  orderBy?: string[];
  fields?: string[];
  groupBy?: string;
}

interface IGrower {
  growerName: string;
  contractId: number;
}

type TRecord = "growers"
  | "productionPlants"
  | "contracts"
  | "varieties"
  | "fields"
  | "regions"
  | "productionActivity"
  | "productionActivity/upcomingHarvest"
  | "sustainabilityActivity"
  | "virtualSeasons"
  | "growers/stats"
  | "virtualSeasons/growerTotals"
  | "virtualSeasons/hasPrediction"
  | "sustainabilityActivity/growerSeasonSummary" | "sustainabilityActivity/seasonSummary"
  | "startActivity";

export async function Sync(type: string, start?: string) {
  let query = "";
  if (start) {
    const stringifiedQuery = queryString.stringify({ start });
    query = `?${stringifiedQuery}`;
  }

  return ApiFetch(`${type}/sync${query}`);
}

export async function ResetSyncStatus(type: string) {
  return ApiFetch<any>(`${type}/sync/reset`);
}

export async function FetchRecords(type: TRecord, options: IFetchOptions) {
  const dbIdx = await getCurrentDbIdx();

  let query = "";
  if (options) {
    const stringifiedQuery = encodeURIComponent(JSON.stringify(options));
    query = `&options=${stringifiedQuery}`;
  }

  const data = await ApiFetch<any>(`${type}?dbIdx=${dbIdx || 0}${query}`);

  const { rows } = data;

  return rows || [];
}

export async function FetchTableRecords(type: string, options: object) {
  const dbIdx = await getCurrentDbIdx();

  let query = "";
  if (options) {
    const stringifiedQuery = encodeURIComponent(JSON.stringify(options));
    query = `&options=${stringifiedQuery}`;
  }

  const data = await ApiFetch<any>(`${type}?dbIdx=${dbIdx || 0}${query}`);

  return data || [];
}

export async function FetchSingleRecord(type: string, id: string | number | undefined) {
  if (!id) {
    return false;
  }

  const dbIdx = await getCurrentDbIdx();

  const data = await ApiFetch<any>(`${type}/${id}?dbIdx=${dbIdx || 0}`);

  if (!data) {
    return {};
  }

  const { rows } = data;

  return rows?.[0] || false;
}

export async function DeleteRecord(type: string, id: string | number | undefined) {
  if (!id) {
    return false;
  }

  const dbIdx = await getCurrentDbIdx();

  const token = await auth.currentUser?.getIdToken();

  const res = await fetch(`${url}${type}/${id}?dbIdx=${dbIdx || 0}`, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${token}`,
      },
  });
  const { data }: any = await res.json();
  return data || false;
}

export async function FetchUserAccounts(customerId: string) {
  const dbIdx = await getCurrentDbIdx();

  return ApiFetch<any>(`accounts/${customerId}?dbIdx=${dbIdx || 0}`);
}

export async function UpsertRecord(type: string, id: string | number | undefined, formData: object) {
  const dbIdx = await getCurrentDbIdx();

  let endpoint = `${url}${type}`;
  let method = "PUT";

  if (id || id === 0) {
    endpoint = `${url}${type}/${id}`;
    method = "POST";
  }

  endpoint = `${endpoint}?dbIdx=${dbIdx || 0}`;

  const token = await auth.currentUser?.getIdToken();

  const res = await fetch(endpoint, {
    method,
    headers: {
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
    body: JSON.stringify(formData),
  });
  const { data }: any = await res.json();
  return data;
}

export async function FetchGrowerVarietyData({ growerIds, virtualSeasonId }: { growerIds: number[] | string[], virtualSeasonId?: number | string }) {
  const dbIdx = await getCurrentDbIdx();

  if (!growerIds) {
    return false;
  }

  const idsString = JSON.stringify(growerIds);

  const data = await ApiFetch<any>(`productionActivity/growerVariety?ids=${idsString}&virtualSeasonId=${virtualSeasonId || 0}&dbIdx=${dbIdx || 0}`);

  if (!data) {
    return {};
  }

  const { rows } = data;

  return rows || false;
}

export async function ApiFetch<T>(path: string): Promise<T> {
  const token = await auth.currentUser?.getIdToken();

  const res = await fetch(`${url}${path}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  if (res.status !== 200) {
    // tslint:disable-next-line: no-object-literal-type-assertion
    return {} as T;
  }

  const { data } = await res.json();
  return data as T;
}

export async function ApiPostWithAuthAndDbIdx<T>(path: string, body?: string): Promise<T> {
  const token = await auth.currentUser?.getIdToken();
  const dbIdx = await getCurrentDbIdx();

  const res = await fetch(`${url}${path}?dbIdx=${dbIdx}`, {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
    method: "POST",
    body,
  });

  const { data, error, message } = await res.json();

  if (res.status === 400 || error) {
    const errorMsg = { error, message };
    throw errorMsg;
  }

  return data as T;
}

export async function ApiPost<T>(path: string, body?: string): Promise<T> {
  const res = await fetch(`${url}${path}`, {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body,
  });
  const { data } = await res.json();
  return data as T;
}
