import React from "react";

interface ILoadingProps {
  text?: string;
  style?: object;
}

const Loading = (props: ILoadingProps) => {
  const text = props.text || "Loading...";
  return (
    <div className="animated fadeIn pt-1 text-center"><div className="sk-spinner sk-spinner-pulse" /> {text}</div>
  );
};

export default Loading;
