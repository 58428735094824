import { IInputChangeEvent } from "./types";

export const removeURLParameter = (url: string, parameter: string) => {
  const urlparts = url.split(`?`);
  if (urlparts.length >= 2) {
      const prefix = `${encodeURIComponent(parameter)}=`;
      const pars = urlparts[1].split(/[&;]/g);

      for (let i = pars.length; i-- > 0;) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
      }
      }

      url = urlparts[0] + (pars.length > 0 ? `?${pars.join(`&`)}` : ``);
      return url;
  }
  return url;
};

export const insertParam = (key: string, value: string) => {
  let currentUrl = window.location.href;
  currentUrl = removeURLParameter(currentUrl, key);

  let queryStart;
  // tslint:disable-next-line:prefer-conditional-expression
  if (currentUrl.indexOf(`?`) !== -1) {
    queryStart = `&`;
  } else {
    queryStart = `?`;
  }

  const newurl = `${currentUrl + queryStart + key}=${value}`;
  window.history.replaceState({ path: newurl }, ``, newurl);
};

const $blue = "#4582EC";
const $indigo = "#6610f2";
const $purple = "#6f42c1";
const $pink = "#e83e8c";
const $red = "#d9534f";
const $orange = "#DB5132";
const $yellow = "#f0ad4e";
const $green = "#00534f";
const $teal = "#20c997";
const $cyan = "#17a2b8";
const $forrest = "#283631";

const blueRGBVgbVal: any = hexToRgb($blue);
const indigoRgbVal: any = hexToRgb($indigo);

const purpleRgbVal: any = hexToRgb($purple);
const pinkRgbVal: any = hexToRgb($pink);
const redRgbVal: any = hexToRgb($red);
const orangeRgbVal: any = hexToRgb($orange);
const yellowRgbVal: any = hexToRgb($yellow);
const greenRgbVal: any = hexToRgb($green);
const tealRgbVal: any = hexToRgb($teal);
const cyanRgbVal: any = hexToRgb($cyan);
const forrestRgbVal: any = hexToRgb($forrest);

export const indigoRgb = `${indigoRgbVal.r}, ${indigoRgbVal.g}, ${indigoRgbVal.b}`;
export const blueRgb = `${blueRGBVgbVal.r}, ${blueRGBVgbVal.g}, ${blueRGBVgbVal.b}`;
export const purpleRgb = `${purpleRgbVal.r}, ${purpleRgbVal.g}, ${purpleRgbVal.b}`;
export const pinkRgb = `${pinkRgbVal.r}, ${pinkRgbVal.g}, ${pinkRgbVal.b}`;
export const redRgb = `${redRgbVal.r}, ${redRgbVal.g}, ${redRgbVal.b}`;
export const orangeRgb = `${orangeRgbVal.r}, ${orangeRgbVal.g}, ${orangeRgbVal.b}`;
export const yellowRgb = `${yellowRgbVal.r}, ${yellowRgbVal.g}, ${yellowRgbVal.b}`;
export const greenRgb = `${greenRgbVal.r}, ${greenRgbVal.g}, ${greenRgbVal.b}`;
export const tealRgb = `${tealRgbVal.r}, ${tealRgbVal.g}, ${tealRgbVal.b}`;
export const cyanRgb = `${cyanRgbVal.r}, ${cyanRgbVal.g}, ${cyanRgbVal.b}`;
export const forrestRgb = `${forrestRgbVal.r}, ${forrestRgbVal.g}, ${forrestRgbVal.b}`;

interface ITableToChartData {
  data: any;
  schema: any;
  labelIdx?: string;
}

function hexToRgb(hex: string) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
}

export const emptyChartDataObj = {
  labels: [],
  data: {},
};

export function tableToChartData({ data, schema, labelIdx }: ITableToChartData) {
  const formattedDataSets = formatDataForChart(data, labelIdx);
  return createDataSetForChartOptions(formattedDataSets, schema);
}

export function formatDataForChart(data?: any, labelIdx?: string) {
  if (!data || data.length === 0) {
    return {};
  }

  const itemKeys = Object.keys(data[0]).filter((key: string | number) => key !== "label");
  const returnData: any = {
    data: {},
    labels: [],
  };

  itemKeys.forEach((key: string) => {
    returnData.data[key] = {
      data: [],
    };
  });

  data.forEach((item: any, idx: number) => {
    let { label } = item;
    if (!label && !labelIdx) {
      label = idx;
    }

    if (labelIdx) {
      label = item[labelIdx];
    }

    itemKeys.forEach((key: string) => {
      if (!item[key]) { return; }

      if (!returnData.labels.includes(label)) {
        returnData.labels.push(label);
      }
      returnData.data[key].data.push(item[key]);
    });
  });

  return returnData;
}

export function createCapacityLine(labels?: object[], capacity?: number): object | null {
  if (!labels || !labels.length) { return null; }

  return {
    borderWidth: 2,
    borderColor: labels.map(() => `rgba(${purpleRgb}, .5)`),
    backgroundColor: labels.map(() => `rgba(${purpleRgb}, 0)`),
    data: labels.map(() => capacity),
    borderDash: [10, 5],
    label: "Weekly Capacity",
  };
}

export function createDataSetForChartOptions(dataSets: any, schema: any, capacity?: number) {

  const returnDataSets: any = [];
  let capData: any;

  if (capacity) {
    capData = createCapacityLine(dataSets.labels, capacity);
  }

  const {labels, data: dataSetData} = dataSets;

  if (!dataSetData) {
    return emptyChartDataObj;
  }

  const dataSetDataKeys = Object.keys(dataSetData);
  const schemaKeys = Object.keys(schema);

  dataSetDataKeys.forEach((key: string) => {
    if (schemaKeys.includes(key)) {
      returnDataSets.push({
        ...schema[key],
        ...dataSetData[key],
      });
    }
  });

  const newReturnDataSets = returnDataSets.map((set: any) => {
    const newBackgroundColors = [];
    const newBorderColors = [];
    const { backgroundColor, borderColor } = set;
    const backgroundsToAdd = set.data.length;

    if (backgroundColor[0] === "GreenRed") {
      for (let a = 0; a < backgroundsToAdd; a++) {
        if (set.data[a] > 0) {
          newBackgroundColors.push("green");
          newBorderColors.push("green");
        } else {
          newBackgroundColors.push("red");
          newBorderColors.push("red");
        }
      }
    } else {
      for (let a = 0; a < backgroundsToAdd; a++) {
        newBackgroundColors.push(backgroundColor[0]);
        newBorderColors.push(borderColor[0]);
      }
    }

    return {
      ...set,
      backgroundColor: newBackgroundColors,
      borderColor: newBorderColors,
    };
  });

  if (capData) {
    newReturnDataSets.push(capData);
  }

  return {
    labels,
    datasets: newReturnDataSets,
  };

}

export function logger(error: any) {
  if (process.env.NODE_ENV !== "production") {
    console.error(error);
  }
  return false;
}

export function getDateOfWeek(w: any, y: any) {
  if (isNaN(w)) {
    return "N/A";
  }

  const d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week

  let date: any = new Date(y, 0, d);
  let dd: any = date.getDate();

  let mm: any = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  date = yyyy + "-" + mm + "-" + dd;
  return date;
}

export function handleInputChange(event: IInputChangeEvent) {
  const { target: { name, value } } = event;
  return { name, value };
}

export function numberWithCommas(x: number | string) {
  if (!x) {return 0; }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const defaultChartOptions = {
  responsive: true,
  elements: {
    line: {
      tension: 0,
    },
    point: {
      radius: 2,
    },
  },
  legend: {
    position: "top",
    labels: {
      fontColor: "#333",
      fontSize: 16,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: "#7b8a8b",
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontColor: "#7b8a8b",
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    ],
  },
};

export function getSummaryStats(data: object[], stat: string) {
  const summaryData: any = data.map((obj: any) => Number(obj[stat]));
  return {
    q1: quartile25(summaryData),
    q2: quartile50(summaryData),
    q3: quartile75(summaryData),
    q4: quartile100(summaryData),
    mean: arrayAverage(summaryData),
    median: median(summaryData),
    min: minimium(summaryData),
    max: maximum(summaryData),
    sum: arraySum(summaryData),
    stDev: arrayStdev(summaryData),
    quartiles: quartiles(data, stat),
  };
}

export function quartiles(data: object[], stat: string) {
  const summaryData: any = data.map((obj: any) => obj[stat]);

  const q1 = quartile25(summaryData);
  const q2 = quartile50(summaryData);
  const q3 = quartile75(summaryData);

  const sortedData = sortByKey(data, stat);

  const returnData = {
    q1Data: sortedData.filter((obj: any) => (Number(obj[stat]) >= 0 && Number(obj[stat]) <= q1)).map((obj) => obj),
    q2Data: sortedData.filter((obj: any) => (Number(obj[stat]) > q1 && Number(obj[stat]) <= q2)).map((obj) => obj),
    q3Data: sortedData.filter((obj: any) => (Number(obj[stat]) > q2 && Number(obj[stat]) <= q3)).map((obj) => obj),
    q4Data: sortedData.filter((obj: any) => (Number(obj[stat]) > q3)).map((obj) => obj),
  };

  return returnData;
}

export const minimium = (data: number[]) => Math.min(...data);
export const maximum = (data: number[]) => Math.max(...data);
export const median = (data: number[]) => quartile50(data);
export const quartile25 = (data: number[]) => quartile(data, 0.25);
export const quartile50 = (data: number[]) => quartile(data, 0.5);
export const quartile75 = (data: number[]) => quartile(data, 0.75);
export const quartile100 = (data: number[]) => quartile(data, 1);

export function sortByKey(array: object[], key: string) {
  const arrayRef = [...array];
  return arrayRef.sort((a: any, b: any) => {
    const x = a[key]; const y = b[key];
    return ((Number(x) < Number(y)) ? -1 : ((Number(x) > Number(y)) ? 1 : 0));
  });
}

export function quartile(data: number[], q: number) {
  data = data.map((d) => Number(d));
  data = arraySortNumbers(data);
  const pos = ((data.length) - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;

  if ((data[base + 1] !== undefined)) {
    return data[base] + rest * (data[base + 1] - data[base]);
  } else {
    return data[base];
  }
}

export function arraySortNumbers(inputarray: number[]) {
  return inputarray.sort((a, b) => {
    return a - b;
  });
}

export function arraySum(t: any) {
  return t.reduce((a: number, b: number) => a + b, 0);
}

export function arrayAverage(data: any) {
  return arraySum(data) / data.length;
}

export function arrayStdev(tab: any) {
  let i = 0;
  let j = 0;
  let total = 0;
  let mean = 0;
  const diffSqredArr = [];
  for (i = 0; i < tab.length; i += 1) {
    total += tab[i];
  }
  mean = total / tab.length;
  for (j = 0; j < tab.length; j += 1) {
    diffSqredArr.push(Math.pow((tab[j] - mean), 2));
  }

  if (diffSqredArr.length === 0) {
    return 0;
  }

  return (Math.sqrt(diffSqredArr.reduce((firstEl, nextEl) => {
    return firstEl + nextEl;
  }) / tab.length));
}

export function movingAvg(array: number[], count: number, qualifier: () => void) {

  // calculate average for subarray
  const avg = (avgArray: any, avgQualifier: any) => {

    let sum = 0;
    let avgCount = 0;
    let avgVal;

    // tslint:disable-next-line: forin
    for (const i in avgArray) {
      avgVal = Number(avgArray[i]);
      if (!avgQualifier || avgQualifier(avgVal)) {
        sum += avgVal;
        avgCount++;
      }
    }

    return sum / avgCount;
  };

  const result = [];
  let val;

  // pad beginning of result with null values
  for (let i = 0; i < count - 1; i++) {
    result.push(null);
  }

  // calculate average for each subarray and add to result
  for (let i = 0, len = array.length - count; i <= len; i++) {

    val = avg(array.slice(i, i + count), qualifier);
    if (isNaN(val)) {
      result.push(null);
    } else {
      result.push(val);
    }
  }

  return result;
}
